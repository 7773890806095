import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import moment from 'moment';
import {
    APP_DATE_FORMAT,
    APP_DATE_TIME_FORMAT,
    NUMBER_OF_VISITS_TODAY,
    USER_ID
} from "./constants";
import { doc, setDoc } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyCmDWvRysJ3M2hTGN9Mqps3fqvrzwurauw",
    authDomain: "pannibakery-565b5.firebaseapp.com",
    projectId: "pannibakery-565b5",
    storageBucket: "pannibakery-565b5.appspot.com",
    messagingSenderId: "556560133352",
    appId: "1:556560133352:web:173ff66f8fe5db43abed5d",
    measurementId: "G-NQS5JTJDS8"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const writeUserData = async () => {
    await setDoc(doc(db, moment().format(APP_DATE_FORMAT), localStorage.getItem(USER_ID)), {
        lastVisitTime: moment().format(APP_DATE_TIME_FORMAT),
        visits: localStorage.getItem(NUMBER_OF_VISITS_TODAY)
    });
}

export default writeUserData;
