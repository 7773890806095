import { useEffect, useState } from 'react';
import './search.scss';
import { translate } from 'react-jhipster';

export interface ISearchProps {
    text: string;
    setText: (text: string) => void;
    style?: React.CSSProperties
}

const Search = (props: ISearchProps) => {
    const [removeZIndex, setRemoveZIndex] = useState(false);
    const [showPulseAnimation, setShowPulseAnimation] = useState(false);
    const isiPhone = /iPhone/.test(navigator.userAgent);

    useEffect(() => {
        setTimeout(() => {
            setRemoveZIndex(true);
        }, 3000)
        setTimeout(() => {
            setShowPulseAnimation(true);
        }, 4000)
    }, [])

    const {
        text,
        setText
    } = props;

    const handleTextChange = (event: any) => {
        setText(event?.target?.value);
    }

    const handleBlur = () => {
        setShowPulseAnimation(true);
    }

    const handleFocus = () => {
        setShowPulseAnimation(false);
    }

    return (
        <>
            <div
                id="search"
                className={showPulseAnimation ? 'search_animation' : ''}
                style={{
                    zIndex: removeZIndex ? 1500 : 1700,
                    position: 'relative',
                }}
            >
                {!isiPhone ? (
                    <svg viewBox="0 0 420 60" >
                        <rect className="bar" />
                        <g className="magnifier">
                            <circle className="glass" />
                            <line className="handle" x1="32" y1="32" x2="44" y2="44"></line>
                        </g>
                        <g className="sparks">
                            <circle className="spark" />
                            <circle className="spark" />
                            <circle className="spark" />
                        </g>
                        <g className="burst pattern-one">
                            <circle className="particle circle" />
                            <path className="particle triangle" />
                            <circle className="particle circle" />
                            <path className="particle plus" />
                            <rect className="particle rect" />
                            <path className="particle triangle" />
                        </g>
                        <g className="burst pattern-two">
                            <path className="particle plus" />
                            <circle className="particle circle" />
                            <path className="particle triangle" />
                            <rect className="particle rect" />
                            <circle className="particle circle" />
                            <path className="particle plus" />
                        </g>
                        <g className="burst pattern-three">
                            <circle className="particle circle" />
                            <rect className="particle rect" />
                            <path className="particle plus" />
                            <path className="particle triangle" />
                            <rect className="particle rect" />
                            <path className="particle plus" />
                        </g>
                    </svg>
                ) : ''}
                <input
                    className='search_input'
                    placeholder={translate('products.search')}
                    type='search'
                    aria-label="Search for inspiration"
                    value={text}
                    onChange={handleTextChange}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    style={isiPhone ? {
                        position: 'absolute',
                        top: 0,
                        width: 420,
                        height: 60,
                        border: '3px solid #444444',
                        padding: '0 60px'
                    } : {
                        padding: '0 30px 0 60px'
                    }}
                />
            </div>
        </>
    )
}

export default Search;