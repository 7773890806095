const translatesRU = {
  navigation: {
    home: 'Главная',
    about: 'О нас',
    products: 'Продукты',
    contact: 'Контакты'
  },
  sideBar: {
    change: 'Изменение языка',
    follow: 'Следуйте в Instagram',
    followButton: 'Подписывайтесь на нас'
  },
  home: {
    subtitle: 'свежеиспеченная рано утром',
    button: 'Попробуйте их'
  },
  about: {
    title: 'Наша история',
    subtitle: 'Мечта и страсть к хлебопекарному делу',
    firstParagraph: `Познакомьтесь с нашим брендом <b>Panni Bakery</b> — новый концепцией, цель которого— превратить
    традиционную кондитерскую в современную с аутентичным вкусом. Наша идея оформилась, когда мы поняли,
    что нам не хватает того «домашнего» вкуса, которого мы всегда искали в кондитерских изделиях.
    Поэтому мы решили развивать бизнес, который бы воспроизводил вкус прошлых лет, используя навыки , полученные от бабушки.`,
    secondParagraph: `Наша философия <b>Panni Bakery</b> проста, но глубока: мы верим в силу натуральных ингредиентов.
    Мы стремимся создавать вкусные продукты, используя только лучшие ингредиенты.
    Каждый торт, выпечка или пирожное, выходящее из нашей печи, — это произведение чистого искусства,
    каждый кусочек который доставляет восхитительные впечатления.
    Присоединяйтесь к нам в этом путешествии и ощутите волшебство, в котором любовь к выпечке и
    преданность натуральным ингредиентам объединяются, создавая кулинарные шедевры..
    <br />
    <br />
    Спасибо, что выбрали нас!
    <br />
    <br />
    С теплом и лаской,
    <b>команда Panni Bakery</b>`
  },
  products: {
    title: 'Сладости!',
    search: 'Ищу что-нибудь сладкое?',
    subtitle: 'Категории:',
    coffee: 'Кофе',
    drinks: 'Напитки',
    cookie: 'Печенье',
    bread: 'Хлеб',
    muffins: 'Маффины',
    pastries: 'Выпечка',
    panini: 'Панини'
  },
  contact: {
    title: 'Связаться!',
    subtitle: 'Мы всегда здесь. Задайте нам любой вопрос.',
    phone: '+373 788 68 406',
    location: 'Bd. Ștefan cel Mare și Sfînt 133',
    email: 'pannibakery@gmail.com',
    schedule: 'График работы',
    daysWork: 'Понедельник - Пятница',
    daysClosed: 'Суббота - Воскресенье',
    closed: 'выходной'
  },
  map: {
    popup: 'Посмотреть на Google Картах'
  },
  title: 'Добро пожаловать!',
  changeLanguage: 'Изменить текущий язык.'
}

export default translatesRU;