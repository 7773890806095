import './rocket.scss'
import rocket from '../../content/rocket.png'
import rocketUp from '../../content/rocket_up.png'
import { useState } from 'react'

export interface IRocket {
    onClick: () => void;
}

const Rocket = (props: IRocket) => {
    const [clicked, setClicked] = useState(false);

    const handleClicked = () => {
        setClicked(true);
        props.onClick();
        setTimeout(() => {
            setClicked(false);
        }, 1000);
    }

    return (
        <div>
            <img
                id="rocket"
                onClick={handleClicked}
                className={clicked ? "rocket_lift_off" : "rocket"}
                src={!clicked ? rocket : rocketUp}
                alt="rocket"
            />
        </div>
    )
}

export default Rocket;