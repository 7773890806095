import React, { useState } from "react";
import { Link } from 'react-router-dom';
import './navigation-bar.scss';
import logo from '../../content/logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import SideBar from "../side-bar/side-bar";
import Menu from "../menu/menu";
import { translate } from "react-jhipster";

export interface INavigationBar {
    setToReRenderWhenLanguageChange: (language: any) => void;
}

const NavigationBar = (props: INavigationBar) => {
    const [sideBarShow, setSideBarShow] = useState(false);
    const [menuShow, setShowMenu] = useState(false);

    const handleSideBarToggle = () => {
        document.querySelector('body')?.classList.toggle('hide_overflow');
        document.querySelector('html')?.classList.toggle('hide_overflow');
        document.getElementById('rocket')?.classList.toggle('hide');
        setSideBarShow(!sideBarShow);
    }

    const handleMenuToggle = () => {
        setShowMenu(!menuShow);
    }

    const handleCloseMenu = () => {
        setShowMenu(false);
    }

    return (
        <>
            {sideBarShow ? (
                <SideBar
                    handleSideBarToggle={handleSideBarToggle}
                    setToReRenderWhenLanguageChange={props.setToReRenderWhenLanguageChange}
                />
            ) : ''}
            <div className="nav">
                <div className="nav_container">
                    <Menu
                        onClick={handleMenuToggle}
                        menuShow={menuShow}
                    />
                    <img
                        alt="logo"
                        src={logo}
                        className="nav_logo"
                    />
                    <div
                        className="nav_link_container"
                    >
                        <Link
                            className="nav_link"
                            to=""
                        >
                            {translate('navigation.home')}
                        </Link>
                        <Link
                            className="nav_link"
                            to="/about"
                        >
                            {translate('navigation.about')}
                        </Link>
                        <Link
                            className="nav_link"
                            to="/products"
                        >
                            {translate('navigation.products')}
                        </Link>
                        <Link
                            className="nav_link"
                            to="/contact"
                        >
                            {translate('navigation.contact')}
                        </Link>
                    </div>
                    <div>
                        <FontAwesomeIcon
                            icon={faGlobe}
                            className="nav_settings"
                            // spin
                            onClick={handleSideBarToggle}
                        />
                    </div>
                </div>
                <div
                    className="nav_menu"
                    style={{
                        height: menuShow ? 360 : 0
                    }}
                >
                    <Link
                        onClick={handleCloseMenu}
                        className="nav_link menu_link"
                        to=""
                    >
                        {translate('navigation.home')}
                    </Link>
                    <Link
                        onClick={handleCloseMenu}
                        className="nav_link menu_link"
                        to="/about"
                    >
                        {translate('navigation.about')}
                    </Link>
                    <Link
                        onClick={handleCloseMenu}
                        className="nav_link menu_link"
                        to="/products"
                    >
                        {translate('navigation.products')}
                    </Link>
                    <Link
                        onClick={handleCloseMenu}
                        className="nav_link menu_link"
                        to="/contact"
                    >
                        {translate('navigation.contact')}
                    </Link>
                </div>
            </div>
        </>
    )
}

export default NavigationBar;