import './product-detail.scss'
import { useEffect, useState } from 'react';
import { Category, IProduct } from '../../content/info/data';
import { useMatch, useNavigate } from 'react-router-dom';
import Spinner from '../../components/spinner/spinner';
import getProduct from '../../utils/data-utils';
import noImage from "../../content/noImage.png";
import CarouselButton from '../../utils/carousel-button/carousel-button';

const ProductDetail = (props: any) => {
    const [loaded, setLoaded] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [product, setProduct] = useState<IProduct>({
        id: 1,
        name: 'Product',
        small_img: [''],
        big_img: [''],
        price: '123lei',
        weight: '321g',
        category: Category.BREAD,
        ingredients: 'Nothing',
    });
    const match = useMatch('/products/:id');
    let navigate = useNavigate();

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, [])

    useEffect(() => {
        if (match?.params?.id) {
            setProduct(getProduct(+match?.params?.id))
        }
    }, [match?.params?.id])

    const handleCloseClicked = () => {
        navigate(-1);
    }

    const handleLoadedFished = () => {
        setLoaded(true);
    }

    const handleNextClicked = () => {
        setLoaded(false);
        setCurrentIndex(prevIndex => prevIndex + 1);
    }

    const handlePreviousClicked = () => {
        setLoaded(false);
        setCurrentIndex(prevIndex => prevIndex - 1);
    }

    return (
        <div className="page">
            <div className='product_detail_container'>
                <div
                    className="product_detail"
                    key={product.id}
                >
                    <svg
                        className="x_close"
                        version="1.1"
                        height="100"
                        width="100"
                        viewBox="0 0 100 100"
                        onClick={handleCloseClicked}
                    >
                        <path className="x_line" d="M 34,32 L 66,68" />
                        <path className="x_line" d="M 66,32 L 34,68" />
                    </svg>
                    <div className='product_detail_image_container'>
                        {product?.big_img.length ? (
                            <>
                                <div className="product_detail_list">
                                    {product?.big_img.map((img, index) => (
                                        <span
                                            key={`product_detail_item-${index}`}
                                            className={`product_detail_item ${currentIndex === index ? 'item_active' : ''}`}
                                        />
                                    ))}
                                </div>
                                {currentIndex > 0 ? (
                                    <CarouselButton onClick={handlePreviousClicked} previous />
                                ) : ''}
                                {currentIndex < product?.big_img.length - 1 ? (
                                    <CarouselButton onClick={handleNextClicked} next />
                                ) : ''}
                            </>
                        ) : ''}
                        {!loaded ? (
                            <div
                                style={loaded ? {} : { width: '100%' }}
                                className='product_detail_spinner_container'
                            >
                                <Spinner />
                            </div>
                        ) : ""}
                        <img
                            onLoad={handleLoadedFished}
                            className={`product_detail_image ${product?.big_img.length ? '' : 'product_detail_no_image'}`}
                            src={product?.big_img.length ? product?.big_img[currentIndex] : noImage}
                            alt=""
                        />
                    </div>
                    <div className="product_detail_content">
                        <div className='product_detail_header'>
                            <div className="product_detail_product_name">
                                {product?.name}
                            </div>
                        </div>
                        <div className="product_detail_body">
                            <div className='product_ingredients_container'>
                                <p className='product_ingredients_title'>
                                    Ingrediente
                                </p>
                                <p className='product_ingredients_detail'>
                                    {product.ingredients}
                                </p>
                            </div>
                        </div>
                        <div className="product_detail_footer">
                            <div className="product_detail_meta">
                                {product?.price}
                            </div>
                            <div className="product_detail_meta">
                                {product?.weight}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductDetail;