import '../../utils/page-utils.scss';
import './home-page.scss';
import croissant from '../../content/croissant.png';
import deliciiRO from '../../content/deliciiRO.png';
import deliciiEN from '../../content/deliciiEN.png';
import deliciiRU from '../../content/deliciiRU.png';
// import fire from '../../content/fire.png';
import flame from '../../content/flame.gif';
import { Link } from 'react-router-dom';
import { translate } from 'react-jhipster';
import { EN, LANGUAGE_CODE, RU } from '../../utils/constants';

const HomePage = (props: any) => {
    return (
        <div className="page">
            <div className="home">
                <div className="home_content">
                    <img
                        src={
                            localStorage.getItem(LANGUAGE_CODE) === RU ?
                                deliciiRU : localStorage.getItem(LANGUAGE_CODE) === EN ?
                                    deliciiEN : deliciiRO
                        }
                        alt="delicii"
                        className="croissant"
                    />
                    <p className="home_short_description">
                        {translate('home.subtitle')}
                    </p>
                    <Link
                        className="home_link"
                        to="/products"
                    >
                        {translate('home.button')}
                        <img
                            style={{ marginLeft: 4 }}
                            src={flame}
                            alt="fire"
                            className="fire"
                        />
                    </Link>
                </div>
                <div
                    className="home_croissant_container">
                    <img
                        src={croissant}
                        alt="croissant"
                        className="croissant"
                    />
                </div>
            </div>
        </div>
    )
}

export default HomePage;