import React, { useEffect, useState } from 'react';
import './App.css';
import { TranslatorContext } from 'react-jhipster';
import translatesRO from './translates/translate-ro'
import translatesEN from './translates/translate-en'
import translatesRU from './translates/translate-ru'
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import HomePage from './pages/home/home-page';
import NavigationBar from './components/navigation-bar/navigation-bar';
import AboutPage from './pages/about/about-page';
import ProductsPage from './pages/products/products-page';
import ContactPage from './pages/contact/contact-page';
import './utils/constants.scss';
import ProductDetail from './pages/product-detail/product-detail';
import {
  LANGUAGE_CODE,
  EN,
  RO,
  RU,
  USER_ID,
  LAST_VISIT_TIME,
  NUMBER_OF_VISITS_TODAY,
  APP_DATE_FORMAT
} from './utils/constants';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import writeUserData from './utils/firebase-utils';
import moment from 'moment';

const App = () => {
  const [toReRenderWhenLanguageChange, setToReRenderWhenLanguageChange] = useState<any>(null);

  useEffect(() => {
    setLocaleTranslations();
    // If user doesn't have a language set the EN as default
    if (_.isNil(localStorage.getItem(LANGUAGE_CODE))) {
      localStorage.setItem(LANGUAGE_CODE, EN)
    }
    // If user doesn't have and id generat and set one
    if (_.isNil(localStorage.getItem(USER_ID))) {
      localStorage.setItem(USER_ID, uuidv4())
    }

    // If user never visited the website before
    if (_.isNil(localStorage.getItem(LAST_VISIT_TIME))) {
      // Set the time as last login time 
      localStorage.setItem(LAST_VISIT_TIME, moment.now() + '');
      // Set the numeber of logins today as 1
      localStorage.setItem(NUMBER_OF_VISITS_TODAY, '1');
      writeUserData();
    } else {
      // If user visited the website before
      const lastLoginTime = moment(Number(localStorage.getItem(LAST_VISIT_TIME)));
      const numberOfVisitsToday = Number(localStorage.getItem(NUMBER_OF_VISITS_TODAY)) || 1;
      // If 10 minutes passed since last visit
      if (lastLoginTime.add(10, 'minutes').isBefore(moment.now())) {
        // Check if the lastTime day is different than now
        if (lastLoginTime.format(APP_DATE_FORMAT) !== moment().format(APP_DATE_FORMAT)) {
          // Set the number of visits as 1
          localStorage.setItem(NUMBER_OF_VISITS_TODAY, '1');
        } else {
          // If is the same day, increase the number of visits
          localStorage.setItem(NUMBER_OF_VISITS_TODAY, numberOfVisitsToday + 1 + '');
        }
        localStorage.setItem(LAST_VISIT_TIME, moment.now() + '');
        writeUserData();
      }
    }
    TranslatorContext.setDefaultLocale(localStorage.getItem(LANGUAGE_CODE) + '');
    setToReRenderWhenLanguageChange(localStorage.getItem(LANGUAGE_CODE));
  }, []);

  useEffect(() => {
    if (_.isNil(toReRenderWhenLanguageChange) && _.isNil(localStorage.getItem(LANGUAGE_CODE))) {
      localStorage.setItem(LANGUAGE_CODE, toReRenderWhenLanguageChange + '');
    }
  }, [toReRenderWhenLanguageChange])

  const setLocaleTranslations = () => {
    TranslatorContext.registerTranslations(RO, translatesRO);
    TranslatorContext.registerTranslations(EN, translatesEN);
    TranslatorContext.registerTranslations(RU, translatesRU);
  }

  return (
    <div className="App">
      <div className="website_back" />
      <BrowserRouter>
        <NavigationBar setToReRenderWhenLanguageChange={setToReRenderWhenLanguageChange} />
        <Routes >
          <Route path={"/"} element={<HomePage />} />
          <Route path={"/about"} element={<AboutPage />} />
          <Route path={"/products/:id"} element={<ProductDetail />} />
          <Route path={"/products"} element={<ProductsPage />} />
          <Route path={"/contact"} element={<ContactPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
