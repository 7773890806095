import { useEffect, useState } from 'react';
import './side-bar.scss';
import ro from '../../content/ro.png';
import ru from '../../content/ru.png';
import en from '../../content/en.png';
import logo from '../../content/logo_insta.jpg';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RO, RU, EN, LANGUAGE_CODE } from '../../utils/constants'
import { TranslatorContext, translate } from 'react-jhipster';

export interface ISideBar {
    handleSideBarToggle: () => void;
    setToReRenderWhenLanguageChange: (language: any) => void;
}

const SideBar = (props: ISideBar) => {
    const [selectedLanguage, setSelectedLanguage] = useState<any>(null);

    useEffect(() => {
        setSelectedLanguage(localStorage.getItem(LANGUAGE_CODE))
    }, [])

    const handleToggleClicked = (language: typeof RO | typeof EN | typeof RU) => {
        setSelectedLanguage(language);
        TranslatorContext.setDefaultLocale(language);
        localStorage.setItem(LANGUAGE_CODE, language);
        props.setToReRenderWhenLanguageChange(language);
    }

    const handleSideBarClose = () => {
        props.handleSideBarToggle();
    }

    const handleFollowClicked = () => {
        window.open(
            'https://www.instagram.com/pannibakery_md/',
            "_blank"
        )
    }

    return (
        <div className="side_bar_overlay">
            <div className="side_bar">
                <div className='side_bar_button_container'>
                    <div className="container zoom_shrink" onClick={handleSideBarClose}>
                        <div className="close_icon zoom_shrink" />
                    </div>
                </div>
                <p className='side_bar_title'>
                    {translate('sideBar.change')}
                </p>
                <div className='side_bar_language_container'>
                    <div
                        className={(selectedLanguage === EN ? 'side_bar_language_selected ' : '') + 'side_bar_language_button'}
                        onClick={() => handleToggleClicked(EN)}
                    >
                        <img
                            src={en}
                            alt="en"
                            className={(selectedLanguage === EN ? 'side_bar_language_selected_img ' : '') + 'en side_bar_language'}
                        />
                    </div>
                    <div
                        className={(selectedLanguage === RO ? 'side_bar_language_selected ' : '') + 'side_bar_language_button'}
                        onClick={() => handleToggleClicked(RO)}
                    >
                        <img
                            src={ro}
                            alt="ro"
                            className={(selectedLanguage === RO ? 'side_bar_language_selected_img ' : '') + 'ro side_bar_language'}
                        />
                    </div>
                    <div
                        className={(selectedLanguage === RU ? 'side_bar_language_selected ' : '') + 'side_bar_language_button'}
                        onClick={() => handleToggleClicked(RU)}
                    >
                        <img
                            src={ru}
                            alt="ru"
                            className={(selectedLanguage === RU ? 'side_bar_language_selected_img ' : '') + 'ru side_bar_language'}
                        />
                    </div>
                </div>
                <p className='side_bar_title'>
                    {translate('sideBar.follow')}
                </p>
                <div className='side_bar_instagram'>
                    <img
                        src={logo}
                        alt='logo'
                        className='side_bar_logo'
                    />
                    <div className='side_bar_info'>
                        <p className='side_bar_name'>
                            pannibakery_md
                        </p>
                        <p className='side_bar_description'>
                            Delicii coapte cu pasiune❤️
                        </p>
                    </div>
                </div>
                <div className='side_bar_button' onClick={handleFollowClicked}>
                    <FontAwesomeIcon
                        icon={faUserPlus}
                        className="side_bar_user"
                    />
                    <p className='side_bar_follow'>
                        {translate('sideBar.followButton')}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default SideBar;