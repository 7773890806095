import React, { useEffect, useState } from "react";
import '../../utils/page-utils.scss';
import './products-page.scss';
import Card from "../../components/card/card";
import products, { Category } from "../../content/info/data";
import Search from "../../components/search/search";
import bread from '../../content/bread.png'
import pastries from '../../content/pastries.png'
import panini from '../../content/panini.png'
import drinks from '../../content/drinks.png'
import Rocket from "../../components/rocket/rocket";
import _ from "lodash";
import { translate } from "react-jhipster";
import unidecode from "unidecode";

const ProductsPage = () => {
    const [text, setText] = useState('');
    const [category, setCategory] = useState<Category>(Category.NONE);
    const [marginTop, setMarginTop] = useState(50);

    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
        })
    }, [])

    useEffect(() => {
        window.addEventListener('scroll', () => {
            const marginTop = document?.querySelector('.page')?.getBoundingClientRect()?.top;
            if (_.isNumber(marginTop)) {
                setMarginTop(marginTop)
            }
        });
    }, [])

    const handleCategoryChange = (categoryName: Category) => {
        if (category === categoryName) {
            setCategory(Category.NONE)
        } else {
            setCategory(categoryName)
        }
    }

    const handleScrollTopClicked = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }

    return (
        <div className="page">
            {marginTop < 50 ? (
                <Rocket onClick={handleScrollTopClicked} />
            ) : ''}
            <div className="products">
                <p className="products_title">
                    {translate('products.title')}
                </p>
                <Search
                    style={{
                        marginTop: 20,
                        marginBottom: 50
                    }}
                    text={text}
                    setText={setText}
                />
                <div className="products_categories">
                    <div className="products_category_title">
                        {translate('products.subtitle')}
                    </div>
                    <div
                        className={(category === Category.PASTRIES ? 'products_category_selected' : '') + " products_category"}
                        onClick={() => handleCategoryChange(Category.PASTRIES)}
                    >
                        <img className="product_category_image" src={pastries} alt="pastries" />
                        <p className="product_category_name">{translate('products.pastries')}</p>
                    </div>
                    <div
                        className={(category === Category.DRINKS ? 'products_category_selected' : '') + " products_category"}
                        onClick={() => handleCategoryChange(Category.DRINKS)}
                    >
                        <img className="product_category_image" src={drinks} alt="drinks" />
                        <p className="product_category_name">{translate('products.drinks')}</p>
                    </div>
                    <div
                        className={(category === Category.PANINI ? 'products_category_selected' : '') + " products_category"}
                        onClick={() => handleCategoryChange(Category.PANINI)}
                    >
                        <img className="product_category_image" src={panini} alt="panini" />
                        <p className="product_category_name">{translate('products.panini')}</p>
                    </div>
                    <div
                        className={(category === Category.BREAD ? 'products_category_selected' : '') + " products_category"}
                        onClick={() => handleCategoryChange(Category.BREAD)}
                    >
                        <img className="product_category_image" src={bread} alt="bread" />
                        <p className="product_category_name">{translate('products.bread')}</p>
                    </div >
                </div >
                <div className="products_container">
                    {products
                        .filter(product => text ? unidecode(product?.name)?.toLowerCase().includes(text.toLowerCase()) : true)
                        .filter(product => category === Category.NONE || product.category === category)
                        .map(product => (
                            <Card product={product} key={product.id} />
                        ))
                    }
                </div>
            </div >
        </div >
    )
}

export default ProductsPage;