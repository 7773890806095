import { useState } from 'react';
import { IProduct } from '../../content/info/data';
import './card.scss';
import { useNavigate } from 'react-router-dom';
import Spinner from '../spinner/spinner';
import noImage from "../../content/noImage.png";

export interface ICardProps {
    product: IProduct
}

const Card = (props: ICardProps) => {
    const [loaded, setLoaded] = useState(false);
    const { product } = props;
    let navigate = useNavigate();

    const handleCardClicked = () => {
        navigate(`/products/${product?.id}`)
    }

    const handleLoadedFished = () => {
        setLoaded(true);
    }
    return (
        <div
            className="card"
            key={product.id}
            onClick={handleCardClicked}
        >
            <div className="card_content">
                <div className='card_header'>
                    {!loaded ? (
                        <div className='card_spinner_container'>
                            <Spinner />
                        </div>
                    ) : ""}
                    <img
                        onLoad={handleLoadedFished}
                        className={`card_image ${product?.small_img.length ? '' : 'card_image_no_image'}`}
                        src={product?.small_img.length ? product?.small_img[0] : noImage}
                        alt="product"
                    />
                </div>
                <div className="card_body">
                    <div className="card_product_name">
                        {product?.name}
                    </div>
                    {/* Button pentru 'Add To Card' next feature */}
                    {/* <button className="icon_button">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" display="block" id="Heart">
                            <path d="M7 3C4.239 3 2 5.216 2 7.95c0 2.207.875 7.445 9.488 12.74a.985.985 0 0 0 1.024 0C21.125 15.395 22 10.157 22 7.95 22 5.216 19.761 3 17 3s-5 3-5 3-2.239-3-5-3z" />
                        </svg>
                    </button> */}
                </div>
                <div className="card_footer">
                    <div className="card_meta">
                        {product?.price}
                    </div>
                    <div className="card_meta">
                        {product?.weight}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Card;