
import previous from "../../content/previous.png";
import next from "../../content/next.png";
import './carousel-button.scss';

export interface ICarouselButton {
    previous?: boolean;
    next?: boolean;
    onClick: () => void;
}

const CarouselButton = (props: ICarouselButton) => {
    return (
        <img
            key={props.previous ? 'previous_button' : 'next_button'}
            onClick={props.onClick}
            className={`carousel_button ${props.previous ? 'previous_button' : ''} ${props.next ? 'next_button' : ''}`}
            src={props.previous ? previous : next}
            alt={props.previous ? 'Previous' : props.next ? 'Next' : 'Switch'}
        />
    );
}

export default CarouselButton;