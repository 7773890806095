import React, { useEffect } from "react";
import '../../utils/page-utils.scss';
import './about-page.scss';
import { translate } from "react-jhipster";

const AboutPage = (props: any) => {
    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
        })
    }, [])

    return (
        <div className="page">
            <div className="about">
                <div className="about_header">
                    <p className="contact_title">
                        {translate('about.title')}
                    </p>
                    <p className="contact_subtitle">
                        {translate('about.subtitle')}
                    </p>
                </div>
                <div className="about_content">
                    <div className="about_container about_top">
                        <p className="about_description about_description_windmill">
                            {translate('about.firstParagraph')}
                        </p>
                        <div className="about_img about_windmill" />
                    </div>
                    <div className="about_container about_bottom">
                        <div className="about_img about_location" />
                        <p className="about_description about_description_location">
                            {translate('about.secondParagraph')}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutPage;