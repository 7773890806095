import React, { useEffect } from "react";
import '../../utils/page-utils.scss'
import './contact-page.scss'
import { faLocationDot, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LocationMap from "../../components/react-map/react-map";
import { translate } from "react-jhipster";

const ContactPage = () => {
    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
        })
    }, [])

    const handlePhoneClicked = () => {
        window.open(
            "tel:+37378868406",
            "_blank"
        );
    }

    const handleLocationClicked = () => {
        window.open(
            'https://www.google.com/maps/place/Panni+Bakery/@47.0303957,28.8203988,17z/data=!3m1!4b1!4m6!3m5!1s0x40c97d409331a6d7:0xf096da1e55554d20!8m2!3d47.0303921!4d28.8229737!16s%2Fg%2F11kpdx7dns?entry=ttu',
            "_blank"
        );
    }

    const handleEmailClicked = () => {
        document.location = `mailto:pannibakery@gmail.com`
    }

    return (
        <div className="page">
            <div className="contact">
                <div className="contact_header">
                    <p className="contact_title">
                        {translate('contact.title')}
                    </p>
                    <p className="contact_subtitle">
                        {translate('contact.subtitle')}
                    </p>
                </div>
                <div className="contact_program">
                    <p className="contact_program_title">{translate('contact.schedule')}</p>
                    <div className="contact_program_content">
                        <p className="contact_program_days">{translate('contact.daysWork')}</p>
                        <p
                            className="contact_program_hours"
                            style={{ marginBottom: 20 }}
                        >
                            07:00 - 19:00
                        </p>
                        <p className="contact_program_days">{translate('contact.daysClosed')}</p>
                        <p
                            className="contact_program_hours"
                            style={{ marginBottom: 0 }}
                        >
                            {translate('contact.closed')}
                        </p>
                    </div>
                </div>
                <div className="contact_info">
                    <div onClick={handlePhoneClicked} className="contact_info_container">
                        <FontAwesomeIcon
                            icon={faPhone}
                            className="contact_icons"
                        />
                        <p className="contact_info_detail">
                            {translate('contact.phone')}
                        </p>
                    </div>
                    <div onClick={handleLocationClicked} className="contact_info_container">
                        <FontAwesomeIcon
                            icon={faLocationDot}
                            className="contact_icons"
                        />
                        <p className="contact_info_detail">
                            {translate('contact.location')}
                        </p>
                    </div>
                    <div onClick={handleEmailClicked} className="contact_info_container">
                        <FontAwesomeIcon
                            icon={faEnvelope}
                            className="contact_icons"
                        />
                        <p className="contact_info_detail">
                            {translate('contact.email')}
                        </p>
                    </div>
                </div>
                <div className="contact_map">
                    <LocationMap />
                </div>
            </div>
        </div>
    )
}

export default ContactPage;