import './react-map.scss';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { Icon, LatLngExpression } from 'leaflet';
import googleMap from '../../content/google-maps.png';
import { translate } from 'react-jhipster';

const LocationMap = () => {
    const position: LatLngExpression = [47.03037, 28.8233]

    const customIcon = new Icon({
        iconUrl: require('../../content/marker.png'),
        iconSize: [45, 45]
    })

    const handleClick = () => {
        window.open(
            'https://www.google.com/maps/place/Panni+Bakery/@47.0303957,28.8203988,17z/data=!3m1!4b1!4m6!3m5!1s0x40c97d409331a6d7:0xf096da1e55554d20!8m2!3d47.0303921!4d28.8229737!16s%2Fg%2F11kpdx7dns?entry=ttu',
            "_blank"
        )
    }

    return (
        // @ts-ignore
        <MapContainer center={position} zoom={23} >
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker
                position={position}
                // @ts-ignore
                icon={customIcon}
            >
                <Popup
                    // @ts-ignore
                    keepInView
                >
                    <div>
                        <div
                            className='popup_container'
                            onClick={handleClick}
                        >
                            <img
                                alt={'Google Maps'}
                                src={googleMap}
                                style={{
                                    marginRight: 3
                                }}
                            />
                            <p
                                className='popup_title'
                                style={{
                                    // @ts-ignore
                                    textWrap: 'nowrap'
                                }}
                            >
                                {translate('map.popup')}
                            </p>
                        </div>
                    </div>
                </Popup>
            </Marker>
        </MapContainer >
    )
}

export default LocationMap;