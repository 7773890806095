export const LANGUAGE_CODE = 'languageCode';
export const USER_ID = 'userId';
export const LAST_VISIT_TIME = 'lastVisitDateTime';
export const NUMBER_OF_VISITS_TODAY = 'numberOfVisitsToday';

export const APP_DATE_FORMAT = 'YYYY-MM-DD';
export const APP_TIME_FORMAT = 'HH:mm:ss.SSS';
export const APP_DATE_TIME_FORMAT = 'DD-MM-YYYY  HH:mm:ss';

export const RO = 'ro';
export const EN = 'en';
export const RU = 'ru';
