const translatesRO = {
    navigation: {
        home: 'Acasă',
        about: 'Despre Noi',
        products: 'Produse',
        contact: 'Contact'
    },
    sideBar: {
        change: 'Schimbați Limba',
        follow: 'Urmăriți pe Instagram',
        followButton: 'Urmăriți-ne'
    },
    home: {
        subtitle: 'Proaspăt frământate dis-de-dimineață',
        button: 'Încearcă-le'
    },
    about: {
        title: 'Povestea noastră',
        subtitle: 'Visul și Pasiunea Brutăriei',
        firstParagraph: `Faceți cunoștință cu brandul nostru <b>Panni Bakery</b>,
        un concept nou care urmărește să transforme patiseria tradițională în una modernă cu gust autentic.
        Ideea noastră a prins contur în momentul în care am realizat că ne lipsește gustul „de acasă”
        și pe care l-am tot căutat în produsele de patiserie. Astfel, am decis să dezvoltăm o afacere
        care să reproducă gustul de altădată, folosind tehnici învățate de la bunica.`,
        secondParagraph: `Filosofia noastră la <b>Panni Bakery este simplă</b>, dar profundă: credem în puterea
        ingredientelor naturale. Ne angajăm să creăm produse delicioase folosind doar cele mai bune componente.
        Orice prăjitură, produs de panificație sau de patiserie care părăsește cuptoarele noastre este un
        produs de artă pură, fiecare mușcătură fiind o experiență încântătoare. Alăturați-vă nouă în această
        călătorie și experimentați magia în care dragostea pentru copt și devotamentul ingredientelor
        naturale se unesc pentru a crea capodopere culinare.
        <br />
        <br />
        Vă mulțumim că ne-ați ales!
        <br />
        <br />
        Cu căldură și îngăduință,
        <b>Echipa Panni Bakery</b>`
    },
    products: {
        title: 'Îndulcește-te!',
        search: 'Cauți Ceva Dulce?',
        subtitle: 'Categorii:',
        coffee: 'Cafea',
        drinks: 'Băuturi',
        cookie: 'Biscuiți',
        bread: 'Pâine',
        muffins: 'Brioșe',
        pastries: 'Patiserie',
        panini: 'Panini'
    },
    contact: {
        title: 'Să ținem legătura!',
        subtitle: 'Suntem mereu aici. Întreabă-ne orice.',
        phone: '+373 788 68 406',
        location: 'Bd. Ștefan cel Mare și Sfînt 133',
        email: 'pannibakery@gmail.com',
        schedule: 'Program de lucru',
        daysWork: 'Luni - Vineri',
        daysClosed: 'Sămbătă - Duminică',
        closed: 'închis'
    },
    map: {
        popup: 'Vizualizați în Hărți Google'
    },
    title: 'Bine Ati Venit!',
    changeLanguage: 'Schimbati limba curenta.'
}

export default translatesRO;