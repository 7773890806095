const translatesEN = {
    navigation: {
        home: 'Home',
        about: 'About',
        products: 'Products',
        contact: 'Contact'
    },
    sideBar: {
        change: 'Change Language',
        follow: 'Follow on Instagram',
        followButton: 'Follow us'
    },
    home: {
        subtitle: 'Freshly kneaded early in the morning',
        button: 'Try it'
    },
    about: {
        title: 'Our story',
        subtitle: 'The Dream and The Passion of Bakery',
        firstParagraph: `Meet our <b>Panni Bakery</b> brand, a new concept that aims to transform the
        traditional pastry into a modern one with authentic taste. Our idea took shape when we realized
        that we were missing the "home taste" that we were always looking for in pastry products.
        Thus, we decided to develop a business that would reproduce the childhood’s taste, using grandma’s
        techniques.`,
        secondParagraph: `Our philosophy at <b>Panni Bakery</b> is simple yet: we believe in the power of
        natural ingredients. We are committed to creating delicious products using only the best ingredients.
        Every cake, bakery or pastry that leaves our ovens is a product of pure art and every bite it is
        a delightful experience. Join us on this journey where love of baking and devotion to natural
        ingredients come together to create culinary masterpieces.
        <br />
        <br />
        Thank you for choosing us!
        <br />
        <br />
        With warmth and indulgence,
        <b>Panni Bakery Team</b>`
    },
    products: {
        title: 'Sweet yourself!',
        search: 'Looking for Something Sweet?',
        subtitle: 'Categories:',
        coffee: 'Coffee',
        drinks: 'Drinks',
        cookie: 'Cookie',
        bread: 'Bread',
        muffins: 'Muffins',
        pastries: 'Pastries',
        panini: 'Panini'
    },
    contact: {
        title: 'Get in touch!',
        subtitle: 'We are always here. Ask us any question.',
        phone: '+373 788 68 406',
        location: 'Bd. Ștefan cel Mare și Sfînt 133',
        email: 'pannibakery@gmail.com',
        schedule: 'Work schedule',
        daysWork: 'Monday - Friday',
        daysClosed: 'Saturday - Sunday',
        closed: 'closed'
    },
    map: {
        popup: 'View in Google Maps'
    },
    title: 'Welcome!',
    changeLanguage: 'Change The Current Language.'
}

export default translatesEN