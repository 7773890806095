import _ from "lodash";
import products from "../content/info/data";

const getProduct = (id: number) => {
    const productsFiltered = products.filter(product => product?.id === id);
    if (_.isEmpty(productsFiltered)) {
        return products[0]
    } else {
        return productsFiltered[0]
    }
}

export default getProduct;